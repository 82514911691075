<template>
  <div class="hu_notice_box">
      <van-nav-bar
      title="企业收录"
      left-text="返回"
      left-arrow
      fixed
      placeholder
      @click-left="$router.go(-1)"
    >
    </van-nav-bar>
    <div class="hu_notice_list">
        <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
        >
            <van-cell v-for="(item,index) in Nlits" :key="index" :title="item.retitle" :value="item.reisonline==1?'在线':'不在线'" :label='item.readdress' :to="'/xdetails/'+item.pkguid"/>
        </van-list>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import {
  com_list_me,
} from "@/api/index";
export default {
    setup(props) {
        let Nlits=ref([]);
        const loading = ref(false);
        const finished = ref(false);
        let quer={
            page:1,
            pagesize:6,
        };
        const Lits_notice=()=>{
            com_list_me(quer).then((res) => {
                // Nlits.value=res.data.mydata.data;
                console.log('ss',res)
                // Nlits.value=[];
                Nlits.value=Nlits.value.concat(res.data.mydata.data);
                loading.value = false;
                if (res.data.mydata.pageall <= quer.page) {
                    finished.value = true;
                }
                quer.page+=1;
            }).catch((err) => {
                console.log(err)
            });
        };
        const onLoad = () => {
            setTimeout(() => {
                Lits_notice();
            }, 1000);
        };
        onMounted(()=>{
            // onLoad();
        });
        return{
            Nlits,
            onLoad,
            loading,
            finished
        }
    }
}
</script>

<style scoped lang="less">
//头部样式
/deep/ .van-nav-bar__placeholder{
  z-index: 99999;
}
/deep/ .van-nav-bar__title{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
/deep/ .van-icon-arrow-left{
  color: #323233;
}
/deep/ .van-nav-bar__text{
  color: #323233;
}
.hu_notice_list{
    margin-top: 0.5rem;
    padding: 0 0.5rem;
}
</style>